import React, { useState } from "react";
import { Input, Modal } from "antd";
import { observer } from "mobx-react-lite";

import { useStore } from "../store";

export const AddGenotype: React.FC = observer(() => {
  const store = useStore();
  const [id, setId] = useState("");
  const [label, setLabel] = useState("");

  return (
    <Modal
      title="Add genotype"
      open={store.isAddGenotypeShown}
      onOk={(e) => {
        if(!id.match(/id_[0-9a-zA-Z]{9}/)) e.preventDefault();
        store.selectUserId(id, label);
        store.isAddGenotypeShown = false;
      }}
      onCancel={() => (store.isAddGenotypeShown = false)}
    >
      <Input
        placeholder="john smith"
        value={label}
        onChange={(e) => setLabel(e.target.value)}
        style={{ margin: '10px 0px' }}
      />
      <Input
        placeholder="id_XXXXXXXXX"
        value={id}
        onChange={(e) => {
          let text = e.target.value;
          if (text.length < 13) {
            if (text.length < 4) {
              if (text.startsWith("id_".substring(0, text.length))) {
                setId(text)
              } else {
                if (`id_${text}`.match(/id_[0-9a-zA-Z]+/))
                  setId(`id_${text}`)
              }
            } else if (text.match(/id_[0-9a-zA-Z]+/)) {
                setId(text)
            }
          }
        }}
      />
    </Modal>
  );
});
