import React, { useContext } from "react";
import { makeAutoObservable } from "mobx";
import axios from "axios";

const ENDPOINT = process.env.REACT_APP_UNHELIX_API as string;

export interface SnpInfo {
  snp: string;
  genotype?: string;
  riskNonRiskAllele?: string;
  majorMinorAllele?: string;
  minorAlleleFrequency?: number;
  reportedGene?: string;
  effectSize?: number;
  pValue?: number;
  personalScore?: number;
  populationScoreAverage?: number;
  populationScoreSd?: number;
  scoreDiff?: number;
  missing?: boolean;
  missingMajorMinor?: boolean;
  missingEffectInfo?: boolean;
  chromosomeName?: string;
}

export interface Snp {
  snp: string;
  genotype: string;
}

interface TraitResult {
  GRS: number;
  snps: SnpInfo[];
  percentage: number;
  distributionCurve: { x: number[]; y: number[] };
}

export interface StudyTrait {
  id: number;
  name: string;
  category: string;
  publicationDate?: string;
  firstAuthor?: string;
  mostRecent?: boolean;
  sampleSize?: number;
  pmid: number;
}

interface Trait {
  id: number;
  name: string;
  category?: string;
  studyTraits: number;
}

interface TraitAllStudies {
  id: number;
  name: string;
  category?: string;
  studyTraits: StudyTrait[];
}

function tryParse(input: string | null | undefined) {
  if (!input) {
    return undefined;
  }

  try {
    return JSON.parse(input);
  } catch {}

  return undefined;
}

type Ethnicity =
  "auto" |
  "ALL"  |
  "EUR"  |
  "AFR"  |
  "AMR"  |
  "EAS"  |
  "SAS"

interface User {
  label: string,
  id: string,
}

class Store {
  currentUserId?: string;
  // TODO: just make a lib for caching like this and use that
  traitsCache?: Trait[];
  snpCache?: { [key: string]: Snp[]};
  studyTraitCache?: TraitAllStudies[];
  userTraitCache?: { [key: string]: {[k in Ethnicity]?: TraitResult}[]};
  currentUserLabel?: string;
  users?: User[];
  isAddGenotypeShown = false;
  ethnicity: Ethnicity = "auto";

  constructor() {
    makeAutoObservable(this);
    this.currentUserId = 
      localStorage.getItem("unhelix_currentUserId") || "id_8940325p9";
    this.currentUserLabel =
      localStorage.getItem("unhelix_currentUserLabel") || "demo";
    this.users = tryParse(localStorage.getItem("unhelix_users")) || [ {id: "id_8940325p9", label: "demo"} ];
  }

  selectUserId(userId: string, userLabel: string) {
    const user = this.users?.find((user) => user.id === userId) || { label: userLabel, id: userId };
    this.users = [
      user,
      ...(this.users?.filter((user) => user.id !== userId) || []),
    ];
    this.currentUserId = user.id;
    this.currentUserLabel = user.label;

    localStorage.setItem("unhelix_currentUserId", this.currentUserId);
    localStorage.setItem("unhelix_currentUserLabel", this.currentUserLabel);
    localStorage.setItem("unhelix_users", JSON.stringify(this.users));
  }

  removeUserId(userId: string) {
    this.users = this.users?.filter((user) => user.id !== userId) || [];
    if (this.currentUserId === userId) {
      this.currentUserId = this.users[0].id || undefined;
      this.currentUserLabel = this.users[0].label || undefined;
    }

    if (this.currentUserId) {
      localStorage.setItem("unhelix_currentUserId", this.currentUserId);
    } else {
      localStorage.removeItem("unhelix_currentUserId");
    }
    if (this.currentUserLabel) {
      localStorage.setItem("unhelix_currentUserLabel", this.currentUserLabel);
    } else {
      localStorage.removeItem("unhelix_currentUserLabel");
    }
    localStorage.setItem("unhelix_users", JSON.stringify(this.users));
  }

  selectEthnicity(ethnicity: Ethnicity) {
    this.ethnicity = ethnicity;
    localStorage.setItem("unhelix_ethnicity", this.ethnicity);
  }

  url(path: string) {
    return ENDPOINT + path;
  }

  // async snps(): Promise<Snp[]> {
  //   try {
  //     const res = await axios.get(this.url(`/snps/`), {
  //       responseType: "json",
  //     });

  //     if (Array.isArray(res.data)) {
  //       console.log(res.data.length)
  //       return res.data;
  //     }
  //   } catch {}

  //   throw new Error("unable to fetch snpsss");
  // }

  async gene(id: string): Promise<SnpInfo[]> {
    try {
      const res = await axios.get(this.url(`/gene/${id}`), {
        responseType: "json",
      });

      if (Array.isArray(res.data)) {
          return res.data
      }
        } catch {}

    throw new Error("unable to fetch dna");
  }

  async snp(id: string): Promise<SnpInfo[]> {
    try {
      const res = await axios.get(this.url(`/snp/${id}`), {
        responseType: "json",
      });

      if (Array.isArray(res.data)) {
          return res.data 
      }
        } catch {}

    throw new Error("unable to fetch snp");
  }

  async userSnps(): Promise<SnpInfo[]> {
    if (!this.currentUserId) {
      throw Error("set currentUserId first!");
    }

    this.snpCache = tryParse(localStorage.getItem("unhelix_snpcache")) ;

    if (!this.snpCache) {
      this.snpCache = {};
    }

    if (this.snpCache[this.currentUserId]?.length) return this.snpCache[this.currentUserId];

    try {
      const res = await axios.get(this.url(`/snps/${this.currentUserId}`), {
        responseType: "json",
      });

      if (Array.isArray(res.data)) {
        // try {
        //   const res2 = await axios.get(this.url(`/snps/`), {
        //     responseType: "json",
        //   });

        //   if (res2.data) {
        //     this.snpCache[this.currentUserId] = res.data.map((x, i) => {
        //       let result = res2.data[x.snp] ?? {};
        //       result.genotype = x.genotype
        //       return result;
        //     })

        //     localStorage.setItem("unhelix_snpcache", JSON.stringify(this.snpCache));
        //     return this.snpCache[this.currentUserId];
        //   }

        // } catch (e){ console.log (e, "fuck")}


          localStorage.setItem("unhelix_snpcache", JSON.stringify(this.snpCache));
          return this.snpCache[this.currentUserId];
      }
        } catch {}

    throw new Error("unable to fetch snps");
  }

  async traits(): Promise<Trait[]> {
    this.traitsCache = tryParse(localStorage.getItem("unhelix_traitcache"));
    if (this.traitsCache) return this.traitsCache;
    try {
      const res = await axios.get(this.url("/studyTraits/byTrait"), {
        responseType: "json",
      });

      if (Array.isArray(res.data)) {
        this.traitsCache = res.data;
        localStorage.setItem("unhelix_traitcache", JSON.stringify(this.traitsCache));
        return res.data;
      }
    } catch {}

    throw new Error("unable to fetch traits");
  }

  async studyTraits(id: number): Promise<TraitAllStudies> {
    if (!this.studyTraitCache) {
      this.studyTraitCache = tryParse(localStorage.getItem("unhelix_studytraitcache"));
      this.studyTraitCache ??= [];
    }

    if (this.studyTraitCache[id]) {
      return this.studyTraitCache[id];
    }

    try {
      const res = await axios.get(this.url(`/studyTraits/byTrait/${id}`), {
        responseType: "json",
      });

      if (res.data) {
        this.studyTraitCache[id] = res.data;
        localStorage.setItem("unhelix_studytraitcache", JSON.stringify(this.studyTraitCache));
        return res.data;
      }
    } catch {}

    throw new Error("unable to fetch study traits");
  }

  async userTrait(traitId: number): Promise<TraitResult> {
    if (!this.currentUserId)
      throw new Error("set userID first!!");
    if (!this.userTraitCache) {
      this.userTraitCache = tryParse(localStorage.getItem("unhelix_usertraitcache"));
      this.userTraitCache ??= {};
    }
    if (!this.userTraitCache[this.currentUserId]) {
      this.userTraitCache[this.currentUserId] ??= [];
    }

    if (this.userTraitCache[this.currentUserId][traitId]) {
      const result = this.userTraitCache[this.currentUserId][traitId][this.ethnicity] // assigned so typescript would stop bitching, tell me how you fixed it if you did
      if (result) {
        return result;
      }
    } else {
      this.userTraitCache[this.currentUserId][traitId] = {}
    }

    try {
      const res = await axios.get(
        this.url(`/dna/${this.currentUserId}/${this.ethnicity}/studyTraits/${traitId}`),
        {
          responseType: "json",
        }
      );

      if (res.data) {
        this.userTraitCache[this.currentUserId][traitId][this.ethnicity] = res.data;
        localStorage.setItem("unhelix_usertraitcache", JSON.stringify(this.userTraitCache));
        return res.data;
      }
    } catch {}

    throw new Error("unable to fetch user trait");
  }
}

const StoreContext = React.createContext(new Store());
export const useStore = () => useContext(StoreContext);
