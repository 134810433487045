import React, { useMemo, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Card, Input, Col, Row, Typography, Statistic } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { FixedSizeGrid } from "react-window";
import { Link, useLocation } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import axios from "axios";


import { useStore } from "../store";

const { Title, Text, Paragraph } = Typography;

const getQueryStringValue = (
    key: string,
    queryString: string = window.location.search
): string => {
    const values = new URLSearchParams(queryString);
    return values.get(key) || "";
};

let debounceTimeout: number;

const setQueryStringWithoutPageReload = (qsValue: string) => {
    const newurl = window.location.protocol + "//" +
                   window.location.host +
                   window.location.pathname + "?" +
                   qsValue;

    window.history.pushState({ path: newurl }, "", newurl);
};

const setQueryStringValue = (
   key: string,
   value: string,
   queryString = window.location.search
) => {
  const old = new URLSearchParams(queryString)
  window.clearTimeout(debounceTimeout)
  debounceTimeout = window.setTimeout(() => {setQueryStringWithoutPageReload(old.toString());}, 200)

};

function useQueryString(key: string, initialValue: string): [value: string, onSetValue: (newValue: string) => void] {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    (newValue: string) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}

export const SnpBrowser: React.FC = observer(() => {
  const store = useStore();
  const { data, isLoading } = useQuery({
    queryKey: ["userSnps"],
    queryFn: async () => await store.userSnps(),
  });
  const [search, setSearch] = useQueryString("search","");
  console.log(data)


  /* const location = useLocation(); */

  /* setSearch(location.pathname.split('/').pop() || search); */

  const filtered = useMemo(() => {
    const trimmed = search
      .toLowerCase()
      .trim()
      .replace(/[\s\t]+/g, " ");
    if (!trimmed || !data) {
      return data;
    }

    const split = trimmed.split(" ");
 
    return data.filter((snp) => {
      const nameSplit = snp.snp.toLowerCase().split(" ");
      return split.every((word: string) =>
        nameSplit.some((nameWord) => nameWord.includes(word))
      );
    });
  }, [data, search]);

  let columns = window.innerWidth < 1028 ? 1 : 3;

  window.addEventListener("resize", () => {
      columns = window.innerWidth < 1028 ? 1 : 3;
  })

  return (
    <div>
      <Title level={2}>SNP search</Title>
      <Input
        size="large"
        placeholder="Search for SNPs, genes, genosets, etc"
        prefix={<SearchOutlined />}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {isLoading && <Text>Loading...</Text>}
      <AutoSizer style={{ height: `${(window.innerHeight - 400) > 200 ? window.innerHeight - 400 : 800}px`, paddingTop: 16, width: '100%' }}>
        {({ height, width }) => (
          <FixedSizeGrid
            height={height}
            width={width}
            columnWidth={width / columns - (20 / columns)}
            rowHeight={200}
            columnCount={columns}
            rowCount={Math.ceil((filtered?.length || 0) / 3)}
          >
            {({ rowIndex, columnIndex, style }) => {
              const i = rowIndex * 3 + columnIndex;
              const snp = filtered?.[i];
              if (!snp) {
                return null;
              }

              return (
                <div
                  style={{
                    ...style,
                      paddingBottom: 16,
                      paddingTop: 16,
                      paddingRight: columnIndex !== columns - 1 ? 16 : 0,
                  }}
                >
                  <Link to={`?select=${snp.snp}`}>
                    <Card title={snp.snp} className="snp" bordered={false}  style={{
                    }}>
                      <Row>
                      <Col span={18}>
                        <Row>
                          <Col span={6}>
                            <strong>{snp.genotype}</strong>
                          </Col>
                          <Col span={6}>
                            repute: <strong> good </strong>
                          </Col>
                          <Col span={6}>
                            magnitude: <strong> good </strong>
                          </Col>
                        </Row>
                        <Row>
                          blah blah blah blah blah blah blah
                        </Row>
                      </Col>
                      <Col span={6}>
                          <Statistic
                            title="Frequency"
                            value={snp.minorAlleleFrequency}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            suffix="%"
                          />
                      </Col>
                      </Row>
                    </Card>
                  </Link>
                </div>
              );
            }}
          </FixedSizeGrid>
        )}
      </AutoSizer>
    </div>
  );
});
