import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Card, Col, Select, Row, Table, Typography, Popover, Tag, Statistic } from "antd";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";

import { SnpInfo, StudyTrait, useStore } from "../store";
import { ColumnsType } from "antd/es/table";

/* SNP format
 *
 * - how common snps are in populations
 * - <div id="population-diversity-chart"></div><script type="text/javascript">
 * - magnitude, other meta info
 * summary
 * - we should grab everything between <!--body text--> and <!--end body text--> and the catagories
   step 1: filtered on "Is_a_snp"
 */

const { Title, Text, Paragraph } = Typography;

function colorHash(str: string): string {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}


function colorizeGenotype(genotype: string, majorMinorAllele: string) {

  const risk = majorMinorAllele.split('/')[0];
  const gen = genotype.split('/');

  return <Tag color="#3e3e3e" style={{display: "flex", gap: "10px", margin: "0", width: "58px"}}><div style={{color: gen[0] === risk ? "red" : "green"}}> { gen[0] } </div> / <div style={{color: gen[1] === risk ? "red" : "green"}}> { gen[1] } </div></Tag>
}

function colorizeMajorMinor(majorMinorAllele: string) {

  const gen = majorMinorAllele.split('/');

  return <Tag color="#3e3e3e" style={{display: "flex", gap: "10px", margin: "0", width: "58px"}}><div style={{color: "red"}}> { gen[0] } </div> / <div style={{color: "green"}}> { gen[1] } </div></Tag>
}
    const pageLink = (snp: string, snpInfo: SnpInfo) => snp?<a href={`/snp/${snp}`}>{snp}</a>:"-";
    const pageTagLink = (snp: string, snpInfo: SnpInfo) =>  <Tag color={colorHash(snp)}>{pageLink(snp, snpInfo)}</Tag>;
    const pageStudyLink = (study: string) =>  <a href={"/trait/" + study}><Tag color={colorHash(study)}>{study}</Tag></a>;

    const columns: ColumnsType<SnpInfo> = [
      {
        title: "SNP",
        dataIndex: "snp",
        key: "snp",
        render: pageTagLink,
      },
      {
        title: "study",
        dataIndex: "studyTrait",
        key: "studyTrait",
        render: pageStudyLink,
      },
      {
        title: "effectAllele",
        dataIndex: "effectAllele",
        key: "effectAllele",
      },
      {
        title: "nonEffectAllele",
        dataIndex: "nonEffectAllele",
        key: "nonEffectAllele",
      },
      {
        title: "effectSize",
        dataIndex: "effectSize",
        key: "effectSize",
      },
      {
        title: "chromosomeName",
        dataIndex: "chromosomeName",
        key: "chromosomeName",
      },
      {
        title: "Genes",
        dataIndex: "reportedGenes",
        key: "reportedGenes",
        render: (genes) => genes ? genes?.split(',')?.map(pageTagLink) : "-",
      },
      /* {
       *   title: "Risk/non-risk allele",
       *   dataIndex: "riskNonRiskAllele",
       *   key: "riskNonRiskAllele",
       *     render: (el, snp) => JSON.stringify(snp),
       * }, */
    ];

export const Gene: React.FC = observer(() => {
  const store = useStore();
  const { id } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["Gene", id],
    queryFn: async () => await store.gene(id ?? ""),
  });

  return (
    <div>
      {isLoading && <Text>Loading...</Text>}
        { data && data[0] &&
            <Table columns={columns} dataSource={data} />
        }
    </div>
  );
});
