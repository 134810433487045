import React from "react";
import { Outlet } from "react-router-dom";
import { Layout, Menu } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { observer } from "mobx-react-lite";

import { useStore } from "./store";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { AddGenotype } from "./modals/AddGenotype";

export const RootLayout: React.FC = observer(() => {
  const store = useStore();

  const selectorItem: ItemType = store.users?.length
    ? {
        label: store.currentUserLabel
          ? `Genotype: ${store.currentUserLabel.substring(0,20)}`
          : "Select genotype",
        key: "genotype",
        children: store.users?.map((user) => ({
          label: `${user.label.substring(0,20)}`,
          key: user.id,
          onClick: () => {
            store.selectUserId(user.id, user.label);
          },
        })),
      }
    : {
        label: "No genotypes available",
        key: "genotype",
        disabled: true,
      };
  const addItem: ItemType = {
    label: "Add genotype",
    key: "add-genotype",
    onClick: () => {
      store.isAddGenotypeShown = true;
    },
  };
  return (
    <Layout className="layout">
      <Header>
        <Menu
          theme="dark"
          mode="horizontal"
          selectable={false}
          items={[
            {
              label: (
                <span className="logo">
                  un<strong>helix</strong>
                </span>
              ),
              key: "logo",
              onClick: () => {
                const win: Window = window;
                win.location = '/';
              },
            },
            selectorItem,
            addItem,
          ]}
        />
      </Header>
      <Content style={{ padding: "25px 66px" }}>
        <div className="site-layout-content">
          <Outlet />
        </div>
      </Content>
      <Footer style={{ textAlign: "center", position: "fixed", bottom: 10, }}>
        unhelix © 2023 | <a style={{ textDecoration: "none", color: "white" }} href="https://discord.gg/yrtEGdFD5f" target="_blank" rel="noreferrer"> discord </a>
      </Footer>
      <AddGenotype />
    </Layout>
  );
});
