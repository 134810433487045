import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Card, Col, Select, Row, Table, Typography, Popover, Tag, Statistic } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { FixedSizeGrid } from "react-window";
import { Link, useParams } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { SnpInfo, StudyTrait, useStore } from "../store";
import { ColumnsType } from "antd/es/table";

const { Title, Text, Paragraph } = Typography;

function colorHash(str: string): string {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

function getFrequency(genotype: string, majorMinorAllele: string, minorAlleleFrequency: number) {


}

function colorizeGenotype(genotype: string, riskNonRiskAllele: string, snp: any) {

  const gen = genotype.split('/');

  if (riskNonRiskAllele == "")
    return <Tag color="#3e3e3e" style={{display: "flex", gap: "10px", margin: "0", width: "58px"}}><div>{gen[0]}</div> / <div>{gen[1]}</div></Tag>

  const risk = riskNonRiskAllele.split('/')[0];
  console.log(risk, gen, snp)

  return <Tag color="#3e3e3e" style={{display: "flex", gap: "10px", margin: "0", width: "58px"}}><div style={{color: gen[0] == risk ? "red" : "green"}}> { gen[0] } </div> / <div style={{color: gen[1] == risk ? "red" : "green"}}> { gen[1] } </div></Tag>
}

function colorizeRiskNonRisk(riskNonRiskAllele: string) {

  const gen = riskNonRiskAllele.split('/');

  return <Tag color="#3e3e3e" style={{display: "flex", gap: "10px", margin: "0", width: "58px"}}><div style={{color: "red"}}> { gen[0] } </div> / <div style={{color: "green"}}> { gen[1] } </div></Tag>
}

const UserTrait: React.FC<{ id: number; studyTrait: StudyTrait }> = observer(
  ({ id, studyTrait }) => {
    const store = useStore();
    const { data, isLoading } = useQuery({
      queryKey: ["dna-trait", id, store.currentUserId, store.ethnicity],
      queryFn: async () => await store.userTrait(id),
    });

    let off = 0;
    const chartData: { x: number; y: number }[] = [];
    if (data?.distributionCurve) {
      for (let i = 0; i < data.distributionCurve.x.length; i++) {
        chartData.push({
          x: data.distributionCurve.x[i],
          y: data.distributionCurve.y[i],
        });
      }
      const minX = Math.min(...data.distributionCurve.x);
      const maxX = Math.max(...data.distributionCurve.x);
      const total = Math.abs(maxX - minX);
      off = (-1 * minX + data.GRS) / total;
    }

    const snpcard = (snpInfo: SnpInfo) => {
                    return <Row>
                      <Col span={18}>
                        <Row>
                          <Col span={6}>
                            <strong>blah</strong>
                          </Col>
                          <Col span={6}>
                            repute: <strong> good </strong>
                          </Col>
                          <Col span={6}>
                            magnitude: <strong> good </strong>
                          </Col>
                        </Row>
                        <Row>
                          blah blah blah blah blah blah blah
                          {snpInfo.majorMinorAllele}
                        </Row>
                      </Col>
                      <Col span={6}>
                          <Statistic
                            title="Frequency"
                            value={(snpInfo.minorAlleleFrequency ?? 0) * 100}
                            precision={2}
                            valueStyle={{ color: '#3f8600', fontSize: 16 }}
                            suffix="%"
                          />
                      </Col>
                      </Row>
    }

    const pageLink = (snp: string, snpInfo: SnpInfo) => snp?<Popover placement="topLeft" title={snp} content={snpcard(snpInfo)} arrowPointAtCenter><a href={`/snp/${snp}`}>{snp}</a></Popover>:"-";
    const pageTagLink = (snp: string, snpInfo: SnpInfo) =>  <Tag color={colorHash(snp)}>{pageLink(snp, snpInfo)}</Tag>;
    const pageTagLinkGene = (snp: string, snpInfo: SnpInfo) =>  <Tag color={colorHash(snp)}><a href={`/gene/${snp}`}>{snp}</a></Tag>;

    const columns: ColumnsType<SnpInfo> = [
      {
        title: "SNP",
        dataIndex: "snp",
        key: "snp",
        render: pageTagLink,
      },
      {
        title: "Your genotype",
        dataIndex: "genotype",
        key: "genotype",
        render: (el, snpInfo) => el ? colorizeGenotype(el, snpInfo.riskNonRiskAllele ?? "", snpInfo.snp) : "-",
      },
      {
        title: "Risk/non-risk allele",
        dataIndex: "riskNonRiskAllele",
        key: "riskNonRiskAllele",
        render: (el, snpInfo) => { console.log(snpInfo.genotype, snpInfo.missingEffectInfo,snpInfo.riskNonRiskAllele,snpInfo.majorMinorAllele, snpInfo.missing); return el ? colorizeRiskNonRisk(el) : "-" },
      },
      {
        title: "Effect size",
        dataIndex: "effectSize",
        key: "effectSize",
        render: (score) => score ? Math.round(score * 1000) / 1000 : "-",
        sorter: (a: SnpInfo, b: SnpInfo) => (a.effectSize ?? 0) - (b.effectSize ?? 0),
      },
      {
        title: "SNP score",
        dataIndex: "personalScore",
        key: "personalScore",
        render: (score) => score ? Math.round(score * 1000) / 1000 : "-",
        sorter: (a: SnpInfo, b: SnpInfo) => (a.personalScore ?? 0) - (b.personalScore ?? 0),
      },
      {
        title: "SNP score (population normalized)",
        dataIndex: "scoreDiff",
        key: "scoreDiff",
        render: (score) => score ? Math.round(score * 1000) / 1000 : "-",
        sorter: (a: SnpInfo, b: SnpInfo) => (a.scoreDiff ?? 0) - (b.scoreDiff ?? 0),
      },
      {
        title: "P-value",
        dataIndex: "pValue",
        key: "pValue",
        render: (el) => el ? el : "-",
        sorter: (a: SnpInfo, b: SnpInfo) => (a.pValue ?? 0) - (b.pValue ?? 0),
      },
      {
        title: "Major/minor allele",
        dataIndex: "majorMinorAllele",
        key: "majorMinorAllele",
        render: (el) => el ? el : "-",
      },
      {
        title: "Minor allele frequency",
        dataIndex: "minorAlleleFrequency",
        key: "minorAlleleFrequency",
        render: (el) => el ? el : "-",
        sorter: (a: SnpInfo, b: SnpInfo) => (a.minorAlleleFrequency ?? 0) - (b.minorAlleleFrequency ?? 0),
      },
      {
        title: "chromosome",
        dataIndex: "chromosomeName",
        key: "chromosomeName",
        render: (el) => el ? el : "-",
      },
      {
        title: "Reported gene",
        dataIndex: "reportedGene",
        key: "reportedGene",
        render: (genes) => genes ? genes?.split(',')?.map(pageTagLinkGene) : "-",
      },
    ];


    // <Popover placement="topLeft" title="Minor allele frequency (MAF)" content="How much of a population has at least one copy of the minor allele">el</Popover>
    const GRS = Math.round((data?.GRS || 0) * 1000) / 1000;
    const percentage = Math.round(data?.percentage || 0);

    return (
      <div>
        {isLoading && <Text>Loading...</Text>}
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart
            width={500}
            height={400}
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 15,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="x"
              label={{
                value: "Risk score",
                position: "insideBottom",
                offset: -10,
                fill: "white",
              }}
            />
            <YAxis
              label={{
                value: "% of population",
                angle: -90,
                position: "insideLeft",
                fill: "white",
              }}
            />
            <Tooltip />
            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="1" y2="0">
                <stop offset={off} stopColor="white" stopOpacity={1} />
                <stop
                  offset={off}
                  stopColor="rgba(255,255,255,0.1)"
                  stopOpacity={1}
                />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="y"
              stroke="white"
              fill="url(#splitColor)"
            />
          </AreaChart>
        </ResponsiveContainer>
        <Paragraph style={{ paddingTop: 16 }}>
          Retrieved {data?.snps.length} SNPs from {studyTrait.firstAuthor} et
          al. (PMID {studyTrait.pmid}). This study reports a total sample size
          of {studyTrait.sampleSize}, as entered on date{" "}
          {studyTrait.publicationDate}.
        </Paragraph>
        <Paragraph>
          For you, we calculated an ancestry-corrected trait Z-score of {GRS}.
          This means that your genetic risk score for this trait is lower than{" "}
          {100 - percentage}% of and higher than {percentage}% of the general
          population.
        </Paragraph>
        <Paragraph>
          {percentage < 20 && "This is a lower score than the average person."}
          {percentage > 90 && "This is a higher score than the average person."}
          {percentage >= 20 &&
            percentage <= 90 &&
            "This is a fairly average score."}
        </Paragraph>
        <Table columns={columns} dataSource={data?.snps} />
      </div>
    );
  }
);

export const Trait: React.FC = observer(() => {
  const store = useStore();
  const { id } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["traits", id],
    queryFn: async () => await store.studyTraits(parseInt(id || "1")),
  });

  const [studyTraitId, setStudyTraitId] = useState<number | undefined>();

  const studyTrait = useMemo(
    () =>
      data?.studyTraits.find((studyTrait) => studyTrait.id === studyTraitId),
    [data, studyTraitId]
  );

  useEffect(() => {
    setStudyTraitId(
      data?.studyTraits.find((studyTrait) => studyTrait.mostRecent)?.id
    );
  }, [data, setStudyTraitId]);

  return (
    <div>
      <Title level={2}>Trait: {data?.name}</Title>
      {isLoading && <Text>Loading...</Text>}
      <Select
        defaultValue={store.ethnicity}
        onChange={(value) => {store.selectEthnicity(value)}}
        options={[
          {
            value: "auto",
            label: "automatic",
          },
          {
            value: "ALL",
            label: "global",
          },
          {
            value: "EUR",
            label: "european",
          },
          {
            value: "AFR",
            label: "african",
          },
          {
            value: "AMR",
            label: "Mixed Ancestery American",
          },
          {
            value: "EAS",
            label: "East Asian",
          },
          {
            value: "SAS",
            label: "South Asian",
          },
        ]}
        style={{ width: "200px", color: "white", marginBottom: "10px" }}
      />
      <Row gutter={[16, 16]} style={{ paddingBottom: 16 }}>
        {data?.studyTraits.map((studyTrait) => (
          <Col span={8} key={studyTrait.id}>
            <Card
              title={studyTrait.name}
              className="trait"
              bordered={false}
              onClick={() => setStudyTraitId(studyTrait.id)}
            >
              <Paragraph>
                <strong>Year:</strong> {new Date(studyTrait.publicationDate || 0).getFullYear()}
              </Paragraph>
              <Paragraph>
                <strong>First Author:</strong> {studyTrait.firstAuthor}
              </Paragraph>
            </Card>
          </Col>
        ))}
      </Row>
      <Title level={3}>User data for study: {studyTrait?.name}</Title>
      {!!studyTrait && !!store.currentUserId && (
        <UserTrait id={studyTrait.id} studyTrait={studyTrait} />
      )}
    </div>
  );
});
