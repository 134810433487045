import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Trait } from "./pages/Trait";
import { Snp } from "./pages/Snp";
import { Gene } from "./pages/Gene";

import { TraitSearch } from "./pages/TraitSearch";
import { SnpBrowser } from "./pages/SnpBrowser";
import { RootLayout } from "./RootLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "",
        element: <TraitSearch />,
      },
      {
        path: "snp",
        element: <SnpBrowser />,
      },
      {
        path: "snp/:id",
        element: <Snp />,
      },
      {
        path: "gene/:id",
        element: <Gene />,
      },
      {
        path: "trait/:id",
        element: <Trait />,
      },
    ],
  },
]);

export const App: React.FC = () => {
  return <RouterProvider router={router} />;
};
