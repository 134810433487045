import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Card, Input, Select, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { FixedSizeGrid } from "react-window";
import { Link } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";

import { useStore } from "../store";

const { Title, Text, Paragraph } = Typography;

export const TraitSearch: React.FC = observer(() => {
  const store = useStore();
  const { data, isLoading } = useQuery({
    queryKey: ["traits"],
    queryFn: async () => await store.traits(),
  });
  const [search, setSearch] = useState("");

  const filtered = useMemo(() => {
    const trimmed = search
      .toLowerCase()
      .trim()
      .replace(/[\s\t]+/g, " ");
    if (!trimmed || !data) {
      return data;
    }

    const split = trimmed.split(" ");

    return data.filter((trait) => {
      const nameSplit = trait.name.toLowerCase().split(" ");
      return split.every((word) =>
        nameSplit.some((nameWord) => nameWord.includes(word))
      );
    });
  }, [data, search]);

  let columns = window.innerWidth < 1028 ? 1 : 3;

  window.addEventListener("resize", () => {
      columns = window.innerWidth < 1028 ? 1 : 3;
  })

  return (
    <div>
      <Title level={2}>trait search</Title>
      <Input
        size="large"
        placeholder="Search for traits"
        prefix={<SearchOutlined />}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {isLoading && <Text>Loading...</Text>}
      <AutoSizer style={{ height: `${(window.innerHeight - 400) > 200 ? window.innerHeight - 400 : 800}px`, paddingTop: 16, width: '100%' }}>
        {({ height, width }) => (
          <FixedSizeGrid
            height={height}
            width={width}
            columnWidth={width / columns - (20 / columns)}
            rowHeight={193}
            columnCount={columns}
            rowCount={Math.ceil((filtered?.length || 0) / 3)}
          >
            {({ rowIndex, columnIndex, style }) => {
              const i = rowIndex * 3 + columnIndex;
              const trait = filtered?.[i];
              if (!trait) {
                return null;
              }

              return (
                <div
                  style={{
                    ...style,
                    paddingBottom: 16,
                    paddingRight: columnIndex !== columns - 1 ? 16 : 0,
                  }}
                >
                  <Link to={`/trait/${trait.id}`}>
                    <Card title={trait.name} className="trait" bordered={false}>
                      <Paragraph>
                        <strong>Category:</strong> {trait.category}
                      </Paragraph>
                      <Paragraph>
                        <strong>Studies:</strong> {trait.studyTraits}
                      </Paragraph>
                    </Card>
                  </Link>
                </div>
              );
            }}
          </FixedSizeGrid>
        )}
      </AutoSizer>
    </div>
  );
});
